import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["actionSelect"];

  triggerModal() {
    let validActions = ["delete", "invite_to_workflow", "add_tags"];

    if (validActions.includes(this.actionSelectTarget.value)) {
      this.triggerMainModal();
    }
  }

  triggerMainModal() {
    document
      .querySelector("[data-controller*='sjabloon--modal']")
      ["sjabloon--modal"].open();
  }
}
