import { Controller } from "stimulus";
import mustache from "mustache/mustache.mjs";

export default class extends Controller {
  connect() {
    this._toggleApplyButton();
  }

  addInput() {
    var numberOfTagsInput = this.element.querySelectorAll(
      "[data-search-contacts-form-tag-entry]"
    ).length;

    var template = document.getElementById("form_search_tags_template").innerHTML;
    var rendered = mustache.render(template, {
      number: numberOfTagsInput,
    });

    document.getElementById('search_contacts_tags_inputs').insertAdjacentHTML('beforeend', rendered);

    this._toggleApplyButton();
  }

  removeInput(event) {
    let targetDomId = 'form_search_tags_'+event.params.id;

    document.getElementById(targetDomId).remove();

    this._toggleApplyButton();
  }

  _toggleApplyButton() {
    var numberOfTagsInput = this.element.querySelectorAll(
      "[data-search-contacts-form-tag-entry]"
    ).length;

    if (numberOfTagsInput == 0) {
      document.getElementById('search_contacts_submit_filters').classList.add('hidden')
    }
    else {
      document.getElementById('search_contacts_submit_filters').classList.remove('hidden')
    }
  }
}
