import { Controller } from "stimulus"
const ibantools = require('ibantools')

export default class extends Controller {
  static targets = ["iban", "invalid", "valid"]

  checkIban() {
    const iban = ibantools.electronicFormatIBAN(this.ibanTarget.value)
    const ibanvalue = ibantools.isValidIBAN(iban)
    if (this.ibanTarget.value != '' && ibanvalue == false) {
      this.invalidTarget.classList.remove("hidden")
      this.validTarget.classList.add("hidden")
    }
    if (this.ibanTarget.value != '' && ibanvalue == true) {
      this.validTarget.classList.remove("hidden")
      this.invalidTarget.classList.add("hidden")
    }
  }

  ibanValueChanged() {
    this.checkIban()
  }
}