import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    buttonsIds: Array
  }

  perform() {
    for (var i = 0 ; i < this.buttonsIdsValue.length ; i++) {
      this._disableButtonWithId(this.buttonsIdsValue[i]);
    }
  }

  _disableButtonWithId(buttonId) {
    let buttonToDisable = document.getElementById(buttonId);

    setTimeout(function() {
      buttonToDisable.disabled = true;
    }, 100);
  }
}

