import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkboxGroup", "checkbox"];

  connect() {

    let checkboxes = this.checkboxTargets
    let firstCheckbox = checkboxes[0]
    let checkboxLength = checkboxes.length
  
    if (firstCheckbox) {
      for (let i = 0; i < checkboxLength; i++) {
          checkboxes[i].addEventListener('change', checkValidity);
      }

      checkValidity();

    }

    document.querySelector('form[id^="form_request_facade_"]').addEventListener("submit", checkValidity)

    function isChecked() {

      for (let i = 0; i < checkboxLength; i++) {
        if (checkboxes[i].checked) return true;
        }
  
        return false;
  
    }
  
    function checkValidity() {
      const errorMessage = document.getElementsByClassName('multiple-choice-error-message')[0].textContent
      const error = !isChecked() ? errorMessage : '';
      firstCheckbox.setCustomValidity(error);
    }

  }

}
