import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "kindExpiration", "block", "emissionDate"];

  connect() {
    if (this.checkboxTarget.checked) {
      this.blockTarget.classList.remove("hidden");

      var radios = this.kindExpirationTarget.getElementsByTagName("input");

      for (var i = 0; i < radios.length; i++) {
        if (radios[i].id != "document_type_kind_expiration") {
          continue;
        }

        if (radios[i].checked) {
          this.hideEmissionDate();
        } else {
          this.showEmissionDate();
        }
      }
    } else {
      this.blockTarget.classList.add("hidden");
      this.hideEmissionDate();
    }
  }

  toggleBlock(e) {
    if (e.target.checked) {
      this.blockTarget.classList.remove("hidden");
    } else {
      this.blockTarget.classList.add("hidden");
    }
  }

  showEmissionDate() {
    this.emissionDateTarget.classList.remove("hidden");
  }

  hideEmissionDate() {
    this.emissionDateTarget.classList.add("hidden");
  }
}
