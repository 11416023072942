import CheckboxSelectAll from "stimulus-checkbox-select-all";

export default class extends CheckboxSelectAll {
  refresh() {
    super.refresh();

    this.switchBatchButton();
  }

  toggle(e) {
    super.toggle(e);

    this.switchBatchButton();
  }

  switchBatchButton() {
    let batchButton = document.getElementById("submit_batch_action");

    if (this.checked.length == 0) {
      batchButton.disabled = true;
      batchButton.classList.add("cursor-not-allowed");
    } else {
      batchButton.disabled = false;
      batchButton.classList.remove("cursor-not-allowed");
    }
  }
}
