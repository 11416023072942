import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["hiddenInput", "selector", "new", "existing"];

  connect() {
    this.toggle();
  }

  toggle() {
    this.hiddenInputTarget.value = this.selectorTarget.value;

    this.hideAllSelect();
    this.showSelect(this.selectorTarget.value);
  }

  hideAllSelect() {
    this.newTarget.style.display = "none";
    this.existingTarget.style.display = "none";
  }

  showSelect(selector) {
    this[selector + "Target"].style.display = "block";
  }
}
