import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["email"];

  connect() {
    let inboxDomain = this.emailTarget
      .getAttribute("data-email")
      .split("@")
      .pop();

    this.linkToInbox(inboxDomain);
  }

  linkToInbox(inboxDomain) {
    let inboxName = "";
    let inboxUrl = "";

    switch (inboxDomain) {
      case "gmail.com":
      case "googlemail.com":
        inboxName = "Gmail";
        inboxUrl = "mail.google.com/mail/u/0/";

        break;

      case "live.com":
      case "hotmail.com":
      case "outlook.com":
        inboxName = "Outlook";
        inboxUrl = "outlook.live.com/owa/";

        break;

      case "yahoo.com":
      case "yahoo.fr":
        inboxName = "Yahoo";
        inboxUrl = "mail.yahoo.com";

        break;

      case "aol.fr":
      case "aol.com":
        inboxName = "AOL Mail";
        inboxUrl = "mail.aol.com";

        break;

      default:
        inboxName = inboxDomain;
        inboxUrl = inboxDomain;
    }

    let href = "https://" + inboxUrl;
    document.getElementById("inbox-href").setAttribute("href", href);
    document.getElementById("inbox-name").innerHTML = inboxName;
  }
}
