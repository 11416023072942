import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button", "openModal", "loading"];

  perform() {
    let controller = this;

    if (this.hasOpenModalTarget && this.openModalTarget.value == "true") {
      this.triggerMainModal();
    }

    this.element.addEventListener("turbo:submit-start", function (e) {
      if (controller.hasLoadingTarget) {
        controller.loadingTarget.classList.remove("hidden");
      }
    });

    this.element.addEventListener("turbo:submit-end", function (e) {
      if (controller.hasLoadingTarget) {
        controller.loadingTarget.classList.add("hidden");
      }
    });

    this.buttonTarget.click();
  }

  triggerMainModal() {
    document
      .querySelector("[data-controller*='sjabloon--modal']")
      ["sjabloon--modal"].open();
  }
}
