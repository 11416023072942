import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["password", "placeholder", "icon"];

  connect() {
    this.element.querySelectorAll('[data-action="click->password-toggle#toggle"]').forEach((elt) => {
      elt.classList.add('cursor-pointer')
    })
  }

  toggle() {
    this.passwordTarget.classList.toggle(this.data.get("toggleClass"))
    this.placeholderTarget.classList.toggle(this.data.get("toggleClass"))
    this.iconTarget.classList.toggle(this.data.get("iconHidden"))
    this.iconTarget.classList.toggle(this.data.get("iconShow"))
  }

  toggleProvider() {
    if (this.passwordTarget.type === 'password') {
      this.passwordTarget.type = 'text'
      this.iconTarget.classList.toggle(this.data.get("iconHidden"))
      this.iconTarget.classList.toggle(this.data.get("iconShow"))
      }
    else {
      this.passwordTarget.type = 'password'
      this.iconTarget.classList.toggle(this.data.get("iconHidden"))
      this.iconTarget.classList.toggle(this.data.get("iconShow"))
    }
  }
}
