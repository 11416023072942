import { Controller } from "stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    let workflowEntries = this.element;
    let existingSortable = Sortable.get(workflowEntries);

    if (existingSortable != null) {
      existingSortable.destroy();
    }

    new Sortable(workflowEntries, {
      handle: '.handle',
      forceFallback: true,
      fallbackClass: "sortable-fallback",
      onEnd: function (event) {
        let data = {};
        data[workflowEntries.getAttribute("data-model")] = {
          position: event.newIndex + 1,
        };

        var xhr = new XMLHttpRequest();
        var csrfToken = document.querySelector("meta[name=csrf-token]").content;

        xhr.open("PUT", event.item.getAttribute("data-url-change-position"));
        xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
        xhr.setRequestHeader("X-CSRF-Token", csrfToken);
        xhr.send(JSON.stringify(data));
      },
    });
  }
}
