import { Controller } from "stimulus"
import { setCookie } from "./utilities.js"

export default class extends Controller {
  static targets = [ "banner" ]

  dismiss() {
    this.bannerTarget.classList.add("hidden")

    setCookie(28, `_${this.data.get("siteName")}_announcement_${this.data.get("id")}`, true)
  }
}

