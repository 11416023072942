import { Controller } from "stimulus";
import * as Turbo from "@hotwired/turbo";

export default class extends Controller {
  switch(e) {
    var formInputName = document.getElementById("form_input_name").value;
    var formInputType = e.target.value;
    var href = e.target.getAttribute("data-form-input-properties-url");

    var csrfToken = document.querySelector("meta[name=csrf-token]").content;

    var data = {
      form_input: {
        name: formInputName,
        type: formInputType,
      },
    };

    fetch(href, {
      method: "POST",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Content-Type": "application/json; charset=UTF-8",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify(data),
    })
      .then((r) => r.text())
      .then(function (html) {
        Turbo.renderStreamMessage(html);
      });
  }
}
