import { Controller } from "stimulus";
import mustache from "mustache/mustache.mjs";
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = ["addButton", "subfieldsList"];
  static values = {
    maxItems: Number,
  };

  connect() {
    var currentChoices = document.getElementById('subfields-wrapper');
    var currentChoicesArray = currentChoices.querySelectorAll(
      "[data-form-input-properties-array-item-number]"
    );
    var currentChoicesCount = currentChoicesArray.length;

    
    let existingSortable = Sortable.get(currentChoices);


    if (existingSortable != null) {
      existingSortable.destroy();
    }

    new Sortable(this.subfieldsListTarget, {
      forceFallback: true,
      fallbackClass: "sortable-fallback",
      onEnd: () => {

        var currentChoicesArray = currentChoices.querySelectorAll(
          "[data-form-input-properties-array-item-number]"
        );
        [].forEach.call(currentChoicesArray, (function (el, i) { el.setAttribute('data-form-input-properties-array-item-number', i + 1); }));
      }
    });

    if (this.maxItemsValue == currentChoicesCount) {
      this.hideAddButton();
    }
  }

  add(e) {
    var currentChoicesCount = this.element.querySelectorAll(
      "[data-form-input-properties-array-item-number]"
    ).length;

    var template = document.getElementById("choices_template").innerHTML;
    var rendered = mustache.render(template, {
      index: currentChoicesCount + 1,
    });

    if (this.maxItemsValue == currentChoicesCount + 1) {
      this.hideAddButton();
    }

    [
      ...this.element.querySelectorAll(
        "[data-form-input-properties-array-item-number]"
      ),
    ]
      .pop()
      .insertAdjacentHTML("afterend", rendered);
  }

  remove(e) {
    this.showAddButton();

    this.element
      .querySelector(
        '[data-form-input-properties-array-item-number="' +
          e.params["position"] +
          '"]'
      )
      .remove();
  }

  showAddButton() {
    this.addButtonTarget.hidden = false;
  }

  hideAddButton() {
    this.addButtonTarget.hidden = true;
  }
}
