import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    waiting: Number,
  };

  perform() {
    var waiting = this.hasWaitingValue ? this.waitingValue : 200;

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.element.requestSubmit();
    }, waiting);
  }
}
