import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect(e) {
    this.element[this.identifier] = this;
    this.toggleClass = this.data.get("class") || "hidden";
  }

  close(e) {

    if (e != null) {
      e.preventDefault();
    }

    this.containerTarget.classList.add(this.toggleClass);

  }
}
