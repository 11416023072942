import { Controller } from "stimulus";
import { setCookie } from "./utilities.js";

export default class extends Controller {
  static targets = ["background", "container", "content", "loading"];

  connect(e) {
    this.element[this.identifier] = this;
    this.toggleClass = this.data.get("class") || "hidden";
    this.setCookieOnClose = this.data.get("cookieOnClose") || false;
    this.stopScroll = this.data.get("stopScroll") || true;
    this.storeCookieForDays = this.data.get("storeCookieForDays") || 28;
    this.stayOpenOnFormSuccess = false;

    if (this.data.get("intervalTimeInSeconds")) {
      this.setTimer();
    }
  }

  open(e) {
    if (e != null && this.newTabOpen(e)) {
      return;
    }

    if (this.stopScroll == true) {
      this.lockScroll();
    }

    this.contentTarget.innerHTML = this.loadingTarget.innerHTML;
    this.containerTarget.classList.remove(this.toggleClass);
  }

  close(e) {
    this.stayOpenOnFormSuccess = false;

    if (e != null) {
      e.preventDefault();
    }

    this.unlockScroll();

    this.containerTarget.classList.add(this.toggleClass);

    if (this.setCookieOnClose) {
      setCookie(
        this.storeCookieForDays,
        `_${this.data.get("cookieName")}`,
        true
      );
    }
  }

  keepOpenOnFormSuccess(e) {
    this.stayOpenOnFormSuccess = true;
  }

  form(e) {
    if (!this.stayOpenOnFormSuccess && e.detail) {
      var stayOpenOnFormSuccessHeader = e.detail.fetchResponse.header(
        "X-Keep-Modal-Open"
      );

      if (!stayOpenOnFormSuccessHeader && e.detail.success) {
        this.close();
      }
    }
  }

  closeBackground(e) {
    if (e.target === this.backgroundTarget) {
      this.close(e);
    }
  }

  closeWithKeyboard(e) {
    if (e.keyCode === 27) {
      this.close(e);
    }
  }

  lockScroll() {
    let scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    document.body.style.overflow = "hidden";
  }

  unlockScroll() {
    document.body.style.paddingRight = null;
    document.body.style.overflow = null;
  }

  setTimer() {
    setTimeout(() => {
      this.open();
    }, Number(this.data.get("intervalTimeInSeconds")) * 1000);
  }

  newTabOpen(e) {
    return e.ctrlKey || e.shiftKey || e.metaKey || (e.button && e.button == 1);
  }
}
