import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content", "icon"];

  connect() {
    this.element.querySelectorAll('[data-action="click->content-toggle#toggle"]').forEach((elt) => {
      elt.classList.add('cursor-pointer')
    })
  }

  toggle() {
    this.contentTargets.forEach((target) => {
      target.classList.toggle(this.data.get("toggleClass"))
    })
    
    if (this.hasIconTarget == true) {
      this.iconTarget.classList.toggle(this.data.get("icon"))
    }
  }
}
