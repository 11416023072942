/* eslint no-console:0 */

import { Turbo, cable } from "@hotwired/turbo-rails"
import "particles.js"

require("@rails/activestorage").start()
require("channels")
require("stylesheets/application.scss")

import "controllers"
import "javascripts"

import "trix"
import "@rails/actiontext"


// Images (jpg, jpeg, png) are imported  here.
// This will copy all static images under `frontend/images` to the output
// folder and reference them with the image_pack_tag helper in views
// (e.g <%= image_pack_tag 'hero.png' %>) or the `imagePath` JavaScript helper
// below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)