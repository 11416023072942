import { Controller } from "stimulus";

export default class extends Controller {
  track(event) {
    let xhr = new XMLHttpRequest();
    let csrfToken = document.querySelector("meta[name=csrf-token]").content;

    let data = {
      event_name: event.params["eventName"],
      event_properties: event.params["eventProperties"] || {},
    };

    xhr.open("POST", "/analytics");
    xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
    xhr.setRequestHeader("X-CSRF-Token", csrfToken);
    xhr.send(JSON.stringify(data));
  }
}
