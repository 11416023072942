import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["isChecked", "blockToToggle"];

  connect() {
    if (this.isCheckedTarget.checked) {
      this.showBlock();
    }
    else {
      this.hideBlock();
    }
  }

  showBlock() {
    this.blockToToggleTarget.classList.remove("hidden");
  }

  hideBlock() {
    this.blockToToggleTarget.classList.add("hidden");
  }
}
